import { render, staticRenderFns } from "./videoManageDetail.vue?vue&type=template&id=29fd7697&scoped=true"
import script from "./videoManageDetail.vue?vue&type=script&lang=js"
export * from "./videoManageDetail.vue?vue&type=script&lang=js"
import style0 from "./videoManageDetail.vue?vue&type=style&index=0&id=29fd7697&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29fd7697",
  null
  
)

export default component.exports