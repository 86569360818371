<!--设备详情-->
<template>
  <div class="contentDetail">
    <div class="contentDetail_box">
      <div class="contentDetail_head" v-show="backShow">
        <div class="contentDetail_headTitle">
          <i class="iconfont iconxiangqing2"></i>
          <span>详情</span>
        </div>
        <el-button class="button backButton" @click="backClickFun"
          >返回</el-button
        >
      </div>
      <div class="contentDetail_nav" v-loading="loading">
        <vue-scroll :ops="ops">
          <p id="pTxt">{{ txt }}</p>
          <div class="contentDetailNav clear">
            <div class="contentDetailNav_box clear">
              <template v-for="(item, index) in detailList">
                <div
                  class="detail_list"
                  v-if="item.showhide"
                  :style="{ width: item.width }"
                >
                  <!--标题-->
                  <div class="detail_list_left" :style="{ width: labelWidth }">
                    {{ item.label }}
                  </div>
                  <!--内容-数组-->
                  <template v-if="item.type === 'array'">
                    <div class="detail_list_right">
                      {{ item.value.join("、") }}
                    </div>
                  </template>
                  <!--内容-单选-->
                  <template v-if="item.type === 'radio'">
                    <div class="detail_list_right">
                      <el-radio-group v-model="item.value">
                        <el-radio
                          v-for="option in item.radiochildren"
                          :label="option.value"
                          :key="option.labelname"
                          disabled
                          >{{ option.labelname }}</el-radio
                        >
                      </el-radio-group>
                    </div>
                  </template>
                  <!--内容-字符串-->
                  <template v-else>
                    <div class="detail_list_right">
                      {{ item.value }}
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <div class="imgCon" v-if="imgBase64">
            <img :src="imgBase64" />
          </div>
        </vue-scroll>
      </div>
      <!--判断文字是否超出-->
      <p id="pTxt">{{ txt }}</p>
    </div>
  </div>
</template>
<!--
detailData:{
  url:"初始化接口",
  data:{初始化传递的参数}
  outUrl:"返回-保存退出的地址"
  labelWidth:"label的宽度",
  option:{ 字段配置
    "type": 类型,
    "label": 标题,
    "value": 内容,
    "showhide": 是否显示
  }
}
-->
<script>
import configBasics from "@/utils/configBasics";

export default {
  name: "videoManageDetail",
  props: ["parameter"],
  data() {
    return {
      /* 图片信息 */
      dialogVisible: false,
      dialogImageUrl: "",
      ops: configBasics.vueScrollOps,
      detailData: {},
      /* 详情数据 */
      detailList: [],
      /* label宽度 */
      labelWidth: "",
      /* 是否禁用提示框 */
      isTooltip: true,
      /* 判断文字是否超出 */
      txt: "",
      // loading加载
      loading: false,
      backShow: true,
      imgBase64: ""
    };
  },
  created() {
    let that = this;
    if (that.parameter == undefined) {
      that.backShow = true;
      that.labelWidth = "190px";
    } else {
      that.detailData = that.parameter;
      that.labelWidth = that.parameter.labelWidth;
      that.backShow = false;
    }
  },
  methods: {
    /* 退出 */
    outFun() {
      this.$router.push({
        path: "/index/leftMenuIndex/videoManage",
        query: {
          menuid: this.$route.query.menuid,
          sysmodel: this.$route.query.sysmodel,
          moduleName: this.$route.query.moduleName
        }
      });
    },
    /* 初始化 */
    detailFun() {
      let that = this;
      let obj = {
        pkId: this.$route.query.id
      };
      that
        .$api("videoManage.getSdlVideoById")
        .invoke(obj)
        .then(({ data }) => {
          let list = data.data;
          this.imgBase64 = list.videopreview;
          that.detailList = [
            {
              width: "50%",
              showhide: true,
              label: "视频类型",
              type: "string",
              value: list.typename
            },
            {
              width: "50%",
              showhide: true,
              label: "视频名称",
              type: "string",
              value: list.videoname
            },
            {
              width: "50%",
              showhide: true,
              label: "视频简介",
              type: "string",
              value: list.memo
            },
            {
              width: "50%",
              showhide: true,
              label: "责任人",
              type: "string",
              value: list.zruser
            },
            {
              width: "50%",
              showhide: true,
              label: "视频状态",
              type: "string",
              value: list.status == "0" ? "正常" : "废弃"
            },
            {
              width: "50%",
              showhide: true,
              label: "视频文件",
              type: "string",
              value: list.filename
            },
            {
              width: "50%",
              showhide: true,
              label: "视频预览图片",
              type: "string",
              value: "视频预览图片.png"
            }
          ];
        });
    },
    /* 返回的点击事件 */
    backClickFun() {
      this.outFun();
    }
  },
  mounted() {
    this.detailFun();
  },
  watch: {
    parameter: {
      handler(val) {
        this.detailData = val;
        this.detailFun();
      }
    },
    deep: true
  }
};
</script>

<style scoped lang="less">
.contentDetail {
  padding: 12px;
}
.imgCon {
  margin-top: 25px;
  width: 320px;
  height: 240px;
  margin-left: 50%;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
